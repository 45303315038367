<template>
  <el-form
    ref="form"
    v-loading="contentLoading"
    :model="model"
    class="form"
    :label-width="$l('project.labelWidth','80px')">
    <el-button size="small" type="primary" style="margin-bottom: 10px" @click="onadd">新增</el-button>
    <el-table
      :data="model"
      label-position="left"
      class="demo-table-expand">
      <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

      <el-table-column prop="tenantId" label="梯龄开始" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.ageFrom" precision="0" min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="梯龄截止" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.ageTo" precision="0" min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorBrakeActionCount" label="制动器动作次数" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.elevatorBrakeActionCount" precision="0" min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorRunningDistance" label="运行距离" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.elevatorRunningDistance" precision="0" min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorDoorOpeningCount" label="开门次数" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.elevatorDoorOpeningCount" precision="0" min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <!--          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>-->
          <el-button
            type="danger"
            @click="ondel(scope.row)">
            删除
          </el-button>
          <!--          <el-button type="primary" @click="goToDetail(scope.row.id)">{{$l("common.detail", "详情")}}</el-button>-->
          <!--          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-button :loading="submitLoading" type="primary" @click="handleSubmit">保存</el-button>
  </el-form>
</template>

<script>
  export default {
    data() {
      return {
        model: [{id:Math.random()}],
        url:"",
      };
    },
    mounted() {
      this.getData();
    },
    methods:{
      getData() {

        // this.contentLoading = true;
        this.$http.get("maintenance/on-demand/config").then(data => {
          this.contentLoading = false;
          if (data instanceof Array) {
            this.model = data.map(item=>{
              item.id = Math.random();
              if (!item.ageFrom) {
                item.ageFrom=0;
              }
              if (!item.ageTo) {
                item.ageTo=99;
              }
              return item;});
          }

        });

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = this.model;
            this.$http
              .save("maintenance/on-demand/config", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      onadd() {
        this.model.push({id:Math.random()});
      },
      ondel(row) {
        this.model=this.model.filter(v=>v.id!==row.id);

      },
    },
  };
</script>

<style scoped>

</style>